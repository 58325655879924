import getHeadSeo from '~/utils/seo';
import getJSONLD from '~/utils/structured-data';
import { defaultAccount as ekomiAccount } from '~/utils/ekomi';

export default (
  context,
  scripts = [],
  freespeeParameter = null,
  microdataOptions = {},
  meta = []
) => {
  const app = useNuxtApp();
  const store = app.$store;
  const i18n = useI18n();
  const route = useRoute();
  const layout = store.state.layout;
  const ekomi = store.state.ekomi;
  const url = route.path;
  const locale = i18n.locale;
  const headData = {
    title: i18n.t('configs.head.title'),
    description: i18n.t('configs.head.description'),
    image: i18n.t('configs.head.image'),
    siteName: i18n.t('configs.head.siteName'),
    ...layout.head,
  };
  const head = getHeadSeo(headData, locale.code, url);
  head?.meta?.push(...meta);

  const freespeeParameterString = freespeeParameter
    ? `__fs_conf.push(['setParameterString','${freespeeParameter}']);`
    : '';
  const withoutAbTasty = route?.query['disable-abtasty'] === 'true';

  if (microdataOptions.hasOrganization) {
    scripts.push(
      getJSONLD('organization', {
        ...layout.footer.data.seo,
        context,
        url,
        reviews: ekomi.reviews[ekomiAccount],
      })
    );
  }

  return {
    ...head,
    script: [
      ...scripts,
      getJSONLD('website', headData),
      {
        type: 'text/javascript',
        hid: 'freespee-snippet',
        innerHTML: `
          var __fs_conf = __fs_conf || [];
          __fs_conf.push(['setAdv',{'id': 'aprilfr'}]);
          __fs_conf.push(['setCookieDomain','.april.fr']);
          ${freespeeParameterString}
          __fs_conf.push(['autoInvoke', false]);
          `,
        body: true,
      },
      {
        type: 'text/javascript',
        hid: 'abtasty-snippet',
        innerHTML: !withoutAbTasty
          ? `
        (function (i, s, o, g, r, a, m) {
          (i[r] = i[r] || []), (i['abtiming'] = 1 * new Date());
          (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
          a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m);
        })(
          window,
          document,
          'script',
          '//try.abtasty.com/f84cb6e53b602b0f2c1ce71e190a6c90.js',
          '_abtasty'
        );
          `
          : null,
      },
      {
        type: 'text/javascript',
        src: 'https://analytics.freespee.com/js/external/fs.min.js',
        defer: true,
        body: true,
      },
    ],
    __dangerouslyDisableSanitizersByTagID: {
      'freespee-snippet': ['innerHTML'],
      'stfaq-snippet': ['innerHTML'],
      'stfaq-contact-snippet': ['innerHTML'],
      'comparadise-snippet': ['innerHTML'],
      'abtasty-snippet': ['innerHTML'],
    },
  };
};
